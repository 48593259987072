const getValue = key => {
  return sessionStorage.getItem(key);
};

const setValue = (key, value) => {
  sessionStorage.setItem(key, value);
};

const removeKey = key => {
  sessionStorage.removeItem(key);
};

export default {
  getValue,
  setValue,
  removeKey,
}
