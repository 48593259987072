import cookieStorage from "../storage/cookie.js";
import storageService from "../storage/session.js";
import widget from "../widget/index.js";

const authorize = authorization => {
  cookieStorage.setCookie("_cx.survey.authorization", authorization, 2);
};

const identify = identifyData => {
  cookieStorage.setCookie(
    "_cx.survey.identify",
    JSON.stringify(identifyData),
    2
  );
};

const getAuthorization = () =>
  cookieStorage.getCookie("_cx.survey.authorization");

const mapApiResponse = (statusCode, responseText) => {
  return {
    statusCode,
    data: !responseText ? null : JSON.parse(responseText)
  };
};

const sendRequest = () => {
  if (!cookieStorage.getCookie("_cx.survey.authorization")) {
    throw new Error(
      "You must call first authorize before try to start any survey."
    );
  }

  return new Promise((resolve, reject) => {
    const request = new window.XMLHttpRequest();
    request.open("POST", SURVEY_URL + "/widget/nps_sends/check_nps_send");
    request.setRequestHeader("Content-Type", "application/json");
    request.setRequestHeader("Authorization", getAuthorization());

    request.onreadystatechange = function () {
      if (this.readyState == 4) {
        if (this.status >= 200 && this.status < 300) {
          resolve(mapApiResponse(this.status, this.responseText));
          return;
        }
        reject(mapApiResponse(this.status, this.responseText));
      }
    };
    const identifyData = cookieStorage.getCookie("_cx.survey.identify");
    request.send(identifyData);
  });
};

const addListenerForNpsSurveys = () => {
  let cancel;
  const promise = new Promise((resolve, reject) => {
    let requesting = false;
    cancel = reject;
    const intervalId = setInterval(() => {
      if (requesting) {
        return;
      }
      requesting = true;
      sendRequest()
        .then(response => {
          if (hasNpsSurvey(response)) {
            clearInterval(intervalId);
            resolve(response.data);
            return;
          }
        })
        .catch(response => {
          clearInterval(intervalId);
          reject(response);
        })
        .finally(() => {
          requesting = false;
        });
    }, SEARCH_INTERVAL_MS);
  });
  return { promise, cancel };
};

let surveyStartRef = {};

const showLegacyNpsWidget = (npsData, cancel = null) => {
  const widgetReference = widget.includeLegacyWidget(
    npsData,
    getAuthorization(),
    () => {
      startSurvey();
    }
  );
  let nps = {
    widgetReference
  };

  if (cancel) {
    nps.cancel = cancel;
  }

  surveyStartRef = {
    ...surveyStartRef,
    nps
  };
};

const showSurveyWidget = (npsData, cancel = null) => {
  const widgetReference = widget.includeSurveyWidget(
    npsData,
    getAuthorization(),
    () => {
      startSurvey();
    }
  );
  let nps = {
    widgetReference
  };

  if (cancel) {
    nps.cancel = cancel;
  }

  surveyStartRef = {
    ...surveyStartRef,
    nps
  };
};

const hasNpsSurvey = response => {
  return response.data && response.data.id;
};

const isLegacyVersion = response => {
  return response.data && response.data.survey_version == null;
};


const startSurvey = () => {
  const widgetSleeping = storageService.getValue("_cx.survey.sleep");
  if (!widgetSleeping) {
    sendRequest().then(response => {
      if (isLegacyVersion(response)) {
        if (hasNpsSurvey(response)) {
          showLegacyNpsWidget(response.data);
          return;
        }

        const { promise, cancel } = addListenerForNpsSurveys();
        promise.then(npsData => {
          showLegacyNpsWidget(npsData, cancel);
        });
      } else {
        if (response?.data?.id) {
          showSurveyWidget(response.data);
          cookieStorage.setCookie("_cx.survey.status", 'active-survey', 2);
          return;
        }

        cookieStorage.setCookie("_cx.survey.status", 'no-survey-found', 2);
        const { promise, cancel } = addListenerForNpsSurveys();
        promise.then(surveyData => {
          showSurveyWidget(surveyData, cancel);
        });
      }
    });
  }
};

const start = (_surveyType = '') => {
  startSurvey();
};

const stop = surveyType => {
  if (surveyStartRef.hasOwnProperty(surveyType)) {
    if (surveyStartRef[surveyType].hasOwnProperty('cancel')) {
      surveyStartRef[surveyType].cancel();
    }
    const widgetSelector = surveyStartRef[surveyType].widgetReference.selector;
    const widgetElement = document.querySelector(widgetSelector);
    if (widgetElement) {
      widgetElement.remove();
    }
  }
};

const clear = (surveyType, infoType) => {
  stop(surveyType);
  if (infoType == "identify" || infoType == "all") {
    cookieStorage.removeCookie("_cx.survey.identify");
  }

  if (infoType == "authorization" || infoType == "all") {
    cookieStorage.removeCookie("_cx.survey.authorization");
  }
};

const status = () => {
  if (cookieStorage.getCookie("_cx.survey.authorization") == '') {
    throw 'CX - Authorization not found!';
  }

  if (cookieStorage.getCookie("_cx.survey.identify") == '') {
    throw 'CX - Identify not set!';
  }

  if (storageService.getValue("_cx.survey.sleep") != null) {
    throw 'CX - User defined, sleep mode.';
  }

  switch (cookieStorage.getCookie("_cx.survey.status")) {
    case 'active-survey':
      throw 'CX - User logged successfully, active survey.';

    case 'no-survey-found':
      throw 'CX - User logged successfully, no survey found.';
  }

  throw 'CX - User defined, waiting surveys.';
}

export default {
  authorize,
  identify,
  start,
  stop,
  clear,
  status
};
